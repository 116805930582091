<template>
  <v-container class="pa-0 mt-2">
    <v-row>
      <v-col>
        <img width="100%" src="images/banner.webp" alt="" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
        cols="12"
        md="6"
        class="pr-1 text-right background-image"
        :style="{
          'background-image': 'url(images/fundo_inicio_texto.webp)',
        }"
      >
        <v-row class="text-huwe">
          <v-col class="pt-8 pb-0 py-md-10 px-5 px-md-10" offset-md="6" md="6">
            <h2 class="mb-5 mb-md-10 heading-1">
              SUA SEDE PRÓPRIA <br />
              <b>EM LAJEADO</b>
            </h2>
            <p>
              Dedicamo-nos a fornecer os serviços mais abrangentes e memoráveis
              com o mais alto nível de dignidade, compaixão e respeito.
            </p>
            <p class="mb-0">
              Compromisso de ajudar você e sua família em seu momento de luto.
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="px-5 px-md-10 pb-10">
            <v-btn
              href="https://wa.me/5551997311319"
              target="_blank"
              dark
              depressed
              color="#3E4095"
              class="px-8"
            >
              <b>SAIBA +</b>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="pl-1">
        <img width="100%" src="images/foto_capa.webp" alt="" />
      </v-col>
    </v-row>
    <v-row justify="center" class="text-huwe mt-5 px-5 px-md-10 text-h4">
      <v-col md="5">
        <v-row class="text-left">
          <v-col>
            <h1 class="heading-news mt-0">
              HUWE CONTA COM DIVERSOS SERVIÇOS
            </h1>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col>
            <h4 class="mb-2">
              DOCUMENTAÇÃO
            </h4>
            <p class="ma-0">
              <span class="mr-1">●</span>
              <a
                class="text-decoration-none"
                target="_blank"
                href="https://api.whatsapp.com/send?phone=5551997311319&text=Ol%C3%A1%2C%20gostaria%20de%20obter%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20servi%C3%A7o%20de%20cart%C3%B3rio."
              >
                Todo serviço de cartório
              </a>
            </p>
            <p class="ma-0">
              <span class="mr-1">●</span>
              <a
                class="text-decoration-none"
                target="_blank"
                href="https://api.whatsapp.com/send?phone=5551997311319&text=Ol%C3%A1%2C%20gostaria%20de%20obter%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20documenta%C3%A7%C3%A3o%20para%20libera%C3%A7%C3%A3o%20do%20corpo."
              >
                Documentação para liberação do corpo
              </a>
            </p>
            <p class="ma-0">
              <span class="mr-1">●</span>
              <a
                class="text-decoration-none"
                target="_blank"
                href="https://api.whatsapp.com/send?phone=5551997311319&text=Ol%C3%A1%2C%20gostaria%20de%20obter%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20translado%20para%20todo%20Brasil%20e%20Exterior."
              >
                Translado para todo Brasil e Exterior
              </a>
            </p>
          </v-col>
        </v-row>
        <v-row class="mt-1">
          <v-col>
            <h4 class="mb-2">
              TRANSLADO
            </h4>
            <p class="ma-0">
              <span class="mr-1">●</span>
              <a
                class="text-decoration-none"
                target="_blank"
                href="https://api.whatsapp.com/send?phone=5551997311319&text=Ol%C3%A1%2C%20gostaria%20de%20obter%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20translado%20internacional."
                >Internacional</a
              >
            </p>
            <p class="ma-0">
              <span class="mr-1">●</span>
              <a
                class="text-decoration-none"
                target="_blank"
                href="https://api.whatsapp.com/send?phone=5551997311319&text=Ol%C3%A1%2C%20gostaria%20de%20obter%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20translado%20nacional."
                >Nacional</a
              >
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h4>ATENDIMENTO</h4>
            <p class="ma-0">
              <span class="mr-1">●</span>
              <a
                class="text-decoration-none"
                target="_blank"
                href="https://api.whatsapp.com/send?phone=5551997311319&text=Ol%C3%A1%2C%20gostaria%20de%20obter%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20atendimento%20na%20sede%20ou%20a%20domic%C3%ADlio."
                >Na sede ou a Domicílio.</a
              >
            </p>
          </v-col>
        </v-row>
        <v-row class="mt-1">
          <v-col>
            <h4>ATAUDE</h4>
            <p class="ma-0">
              <span class="mr-1">●</span>
              <a
                class="text-decoration-none"
                target="_blank"
                href="https://api.whatsapp.com/send?phone=5551997311319&text=Ol%C3%A1%2C%20gostaria%20de%20obter%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20diversos%20modelos%20de%20ata%C3%BAde."
              >
                Diversos modelos
              </a>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0">
            <v-btn
              href="https://wa.me/5551997311319"
              target="_blank"
              depressed
              dark
              color="green"
              class="mb-3 mb-md-2 ml-2"
            >
              <v-icon left color="white" v-text="'mdi-whatsapp'" />
              <b>Chame no Whatsapp</b>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      class="background-image text-huwe py-10 mt-10"
      :style="{
        'background-image': 'url(images/fundo_contato.webp)',
      }"
      justify="center"
    >
      <v-col md="8">
        <v-row justify="center">
          <v-col cols="12" md="6" class="px-5 px-md-10 pl-md-0">
            <h1 class="border-contact">
              ENTRE EM CONTATO
            </h1>
            <h3 class="text-center my-5">
              Lajeado - RS
            </h3>

            <v-row justify="center">
              <v-col cols="12" md="10" class="text-center">
                <v-row no-gutters>
                  <v-col cols="4" md="3" class="text-right">
                    <v-icon left color="#669933" v-text="'mdi-whatsapp'" />
                  </v-col>
                  <v-col class="text-subtitle-2 text-left">
                    <a
                      href="https://wa.me/5551997311319"
                      target="_blank"
                      class="text-decoration-none"
                    >
                      (51) 99731-1319
                    </a>
                    <br />
                    <a
                      href="tel:5140641452"
                      target="_blank"
                      class="text-decoration-none"
                    >
                      (51) 4064 1452
                    </a>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row justify="center" class="mt-0">
              <v-col md="10" class="text-center">
                <v-row no-gutters>
                  <v-col cols="4" md="3" class="text-right">
                    <v-icon left color="#669933" v-text="'mdi-email'" />
                  </v-col>
                  <v-col class="text-subtitle-2 text-left">
                    <a
                      href="mailto:funerariahuwe@outlook.com.br"
                      target="_blank"
                      class="text-decoration-none"
                      >funerariahuwe@outlook.com.br</a
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row justify="center" class="mt-0">
              <v-col md="10" class="text-center">
                <v-row no-gutters>
                  <v-col cols="4" md="3" class="text-right">
                    <v-icon left color="#669933" v-text="'mdi-map-marker'" />
                  </v-col>
                  <v-col class="text-subtitle-2 text-left">
                    <a href="https://goo.gl/maps/jfhsp7QT1DXF2DxA6" target="_blank" class="text-decoration-none">
                      Av Benjamin Constant, nº 4180 <br />
                      Montanha, Lajeado - RS <br />
                      CEP: 95905-080
                    </a>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" class="px-5">
            <h3>
              Entre em contato conosco pelo nosso formulário ou venha nos
              visitar.
            </h3>

            <v-form class="mt-5">
              <validation-observer ref="observer">
                <validation-provider v-slot="{ errors }" vid="name">
                  <v-text-field
                    v-model="contact.name"
                    hide-details="auto"
                    label="Seu nome *"
                    solo
                    dense
                    flat
                    :error-messages="errors"
                  />
                </validation-provider>
                <validation-provider v-slot="{ errors }" vid="phoneNumber">
                  <v-text-field
                    v-model="contact.phoneNumber"
                    v-mask="'(##) #####-####'"
                    type="tel"
                    class="mt-2"
                    hide-details="auto"
                    label="Seu telefone *"
                    solo
                    dense
                    flat
                    :error-messages="errors"
                  />
                </validation-provider>
                <validation-provider v-slot="{ errors }" vid="email">
                  <v-text-field
                    v-model="contact.email"
                    type="email"
                    class="mt-2"
                    hide-details="auto"
                    label="Seu email *"
                    solo
                    dense
                    flat
                    :error-messages="errors"
                  />
                </validation-provider>
                <validation-provider v-slot="{ errors }" vid="message">
                  <v-textarea
                    v-model="contact.message"
                    class="mt-2"
                    hide-details="auto"
                    label="Sua mensagem"
                    solo
                    flat
                    :error-messages="errors"
                  />
                </validation-provider>
              </validation-observer>
            </v-form>
            <v-row>
              <v-col>
                <p>* Preenchimento obrigatório</p>
                <v-btn
                  block
                  dark
                  depressed
                  color="#669933"
                  :loading="loadingSendContact"
                  @click="sendContact"
                >
                  <b>Enviar Mensagem</b>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters class="mt-5">
      <v-col>
        <h1 class="text-huwe text-center mb-2">
          ONDE ESTAMOS
        </h1>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3474.2667806504237!2d-51.99483862534852!3d-29.450219203974534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951c63d46b864663%3A0xdd1864abaf30450f!2sHuwe%20Servi%C3%A7os%20Funer%C3%A1rios%20-%20Capelas%20Ecum%C3%AAnicas!5e0!3m2!1spt-BR!2sbr!4v1688510708865!5m2!1spt-BR!2sbr" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import contactApi from '@/api/contact'

export default {
  data: () => ({
    loadingSendContact: false,
    contact: {},
  }),

  methods: {
    async sendContact() {
      try {
        this.loadingSendContact = true
        this.$refs.observer.reset()

        await contactApi.send(this.contact)

        this.$snackbar.show({
          color: 'success',
          message:
            'Sua mensagem foi enviada com sucesso! Retornaremos o mais breve possível.',
        })

        this.contact = {}
      } catch (e) {
        console.log(e)
        if (e.response.status === 422) {
          this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
          return
        }

        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingSendContact = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.background-image
  background-size: cover

.border-contact
  border-bottom: 3px solid $green-site

.line-footer
  height: 2px
  width: 100%
  background-color: white

.heading-news
  line-height: 2rem
</style>
